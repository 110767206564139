import React, {useEffect, useMemo} from 'react';
import {Stack, IComboBoxOption, IComboBox} from '@fluentui/react';
import {InputField, SelectBox} from '../../../common';
import {ICarpoolDetailsProps, carpoolStyle} from '.';
import {carpool, emptyString, MemberType, TelemetryConstants} from '../../../../configs';
import {optionsCarPoolData} from '../../../../mocks';
import {useDispatch, useSelector} from 'react-redux';
import {CarpoolDeleteButton} from '../CarpoolDeleteButton';
import Config from '../../../../configs/constants/uiConfig';
import {CarpoolDetailUsageEvents} from '../../../../configs/usageEvents';
import {getTelemetryClient, IUser, RootState, setCarpoolMemberDetail} from '@microsoft/smart-parking-coreui';

export const CarpoolDetail = (props: ICarpoolDetailsProps): JSX.Element => {
  const {carpoolDivStyle, carpoolDropdownStyle} = carpoolStyle;
  const {carpoolData, handleInputChange, index, carpoolError, carpoolMemberAreaRef} = props;
  const user: IUser = useSelector((state: RootState) => state.user);
  const aliasName: string = useSelector((state: RootState) => state.vehicleDetails.userAlias);
  const dispatch = useDispatch();

  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const logProperties = useMemo(() => ({...user, ...carpoolData}), [carpoolData, user]);

  useEffect(() => {
    if (index === 0) {
      if (carpoolData.memberType === MemberType.NonMsEmployee) {
        telemetryClient.startTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberEmail);
        dispatch(
          setCarpoolMemberDetail({
            index: index,
            formName: carpool.email.name,
            formValue: aliasName + '@microsoft.com',
          }),
        );
        dispatch(
          setCarpoolMemberDetail({
            index: index,
            formName: carpool.alias.name,
            formValue: emptyString,
          }),
        );
        telemetryClient.stopTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberEmail, logProperties);
      } else {
        telemetryClient.startTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberAlias);
        dispatch(
          setCarpoolMemberDetail({
            index: index,
            formName: carpool.alias.name,
            formValue: aliasName,
          }),
        );
        telemetryClient.stopTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberAlias, logProperties);
      }
    }
  }, [aliasName, carpoolData, dispatch, index, logProperties, telemetryClient]);

  /*istanbul ignore next*/
  useEffect(() => {
    telemetryClient.startTrackPage(CarpoolDetailUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(CarpoolDetailUsageEvents.root, undefined, logProperties);
    };
  }, [telemetryClient, logProperties]);

  const emailLabel =
    carpoolData.memberType === MemberType.NonMsEmployee
      ? index === 0
        ? carpool.email.ownerEmailLabel
        : carpool.email.nonMsEmployeeLabel
      : carpoolData.memberType === MemberType.ScoopEmployee
      ? carpool.email.scoopEmployeeLabel
      : carpool.email.nonMsEmployeeLabel;

  const emailPlaceHolder =
    carpoolData.memberType === MemberType.NonMsEmployee
      ? carpool.email.placeHolderNonMs
      : carpoolData.memberType === MemberType.ScoopEmployee
      ? carpool.email.placeHolderScoop
      : carpool.email.placeHolderNonMs;

  return (
    <div>
      <Stack
        horizontal
        className={carpoolData.memberType !== MemberType.ScoopEmployee ? carpoolDivStyle : carpoolDropdownStyle}>
        <SelectBox
          label={carpool.groupMember.label}
          placeholder={carpool.groupMember.placeHolder}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            telemetryClient.startTrackEvent(CarpoolDetailUsageEvents.SelectCarpoolGroupMember);
            handleInputChange(event, carpool.groupMember.name, option, index);
            telemetryClient.stopTrackEvent(CarpoolDetailUsageEvents.SelectCarpoolGroupMember, logProperties);
          }}
          options={optionsCarPoolData}
          required
          text={carpoolData.memberType}
          errorMessage={carpoolError?.memberType}
          componentRef={carpoolMemberAreaRef}
        />
        {!carpoolData.memberType || carpoolData.memberType === MemberType.MsEmployee ? (
          <InputField
            inputType="text"
            name={carpool.alias.name}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              telemetryClient.startTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberAlias);
              handleInputChange(event.currentTarget.value, carpool.alias.name, {}, index);
              telemetryClient.stopTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberAlias, {
                ...logProperties,
              });
            }}
            placeholder={carpool.alias.placeHolder}
            value={carpoolData.memberAlias}
            required
            validateOnLoad={false}
            label={index === 0 ? carpool.alias.ownerAliasLabel : carpool.alias.label}
            disabled={index === 0 ? true : false}
            errorMessage={carpoolError?.memberAlias}
          />
        ) : (
          <InputField
            inputType="text"
            name={carpool.email.name}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              telemetryClient.startTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberEmail);
              handleInputChange(event.currentTarget.value, carpool.email.name, {}, index);
              telemetryClient.stopTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberEmail, {
                ...logProperties,
              });
            }}
            placeholder={emailPlaceHolder}
            value={carpoolData.email}
            required
            validateOnLoad={false}
            label={emailLabel}
            disabled={false}
            errorMessage={carpoolError?.email}
          />
        )}
        {carpoolData.memberType !== MemberType.ScoopEmployee && (
          <>
            <InputField
              inputType="text"
              name={carpool.originCity.name}
              onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                telemetryClient.startTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberOriginCity);
                handleInputChange(
                  event.currentTarget.value.replace(/[^a-z0-9\s]/gi, emptyString),
                  carpool.originCity.name,
                  {},
                  index,
                );
                telemetryClient.stopTrackEvent(CarpoolDetailUsageEvents.InputCarpoolGroupMemberOriginCity, {
                  ...logProperties,
                });
              }}
              placeholder={carpool.originCity.placeHolder}
              value={carpoolData.originCity}
              required
              validateOnLoad={false}
              label={carpool.originCity.label}
              errorMessage={carpoolError?.originCity}
            />
            <InputField
              inputType="text"
              name={carpool.permitNumber.name}
              onChange={event => {}}
              placeholder={carpool.permitNumber.placeHolder}
              value={emptyString}
              description={carpool.permitNumber.description}
              required={false}
              validateOnLoad={false}
              disabled={true}
              IconLabel={{
                title: emptyString,
                icon: emptyString,
                label: carpool.permitNumber.label,
              }}
            />
          </>
        )}
        <CarpoolDeleteButton index={index} />
      </Stack>
    </div>
  );
};
