import {IFormState, RootState, IUser, useGetConfigQuery, regionId} from '@microsoft/smart-parking-coreui';
import React from 'react';
import {useSelector} from 'react-redux';
import {OwnerDetails} from '../OwnerDetails';
import {PolicyCheck} from '../PolicyCheck';
import {VehicleDetails} from '../VehicleDetails';
import {IRegistrationFormProps} from './RegistrationForm.types';
import {ChaufferDetails} from '../ChaufferDetails';
import {countryListToShowChaufferSection, ConfigKey} from '../../../configs';

export const RegistrationForm = ({isFormAdmin}:IRegistrationFormProps): JSX.Element => {
  const isEditFormState: IFormState = useSelector((state: RootState) => state.formState);
  const user: IUser = useSelector((state: RootState) => state.user);
  const {data: configData = {}} = useGetConfigQuery(regionId);
  const isDisablePolicy = isEditFormState.isFormEdit;
  const [showChaufferSection, setShowChaufferSection] = React.useState<Boolean>(true);
  const chaufferFeatureValue = JSON.parse(configData[ConfigKey.ChaufferSectionFeatureFlag] as string);
  return (
    <>
       <VehicleDetails isFormAdmin={isFormAdmin} setShowChaufferSection={setShowChaufferSection} />
       <OwnerDetails />
       {chaufferFeatureValue.enabled && showChaufferSection && countryListToShowChaufferSection.includes(user?.country!) ? <ChaufferDetails /> : null}
       <br />
      {!isDisablePolicy ? <PolicyCheck /> : null}
    </>
  );
};
