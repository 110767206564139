import React, {useEffect} from 'react';
import {Stack, IComboBoxOption, Text, IComboBox, Checkbox} from '@fluentui/react';
import dayjs from 'dayjs';
import {InputField, SelectBox} from '../../common';
import {newLicenseCheckBoxStyles, VehicleRootStyles} from './VehicleDetails.styles';
import {vehicleDetail, emptyString, EmptySpaceString, Motorcycle, TelemetryConstants} from '../../../configs';
import {optionsVehicleRegistration} from '../../../mocks';
import {useDispatch, useSelector} from 'react-redux';
import {VehicleDetailsUsageEvents} from '../../../configs/usageEvents';
import {
  carpoolInitialState,
  getTelemetryClient,
  IFormState,
  IRegisteredVehicleDataItem,
  IRegisterVehicleErrorItem,
  IUser,
  regionId,
  resetCarpool,
  RootState,
  setRegisterFormState,
  setVehicleDetails,
  setVehicleRegisterError,
  useGetConfigQuery,
} from '@microsoft/smart-parking-coreui';
import Config, {ConfigKey} from '../../../configs/constants/uiConfig';
import {
  isRegistrationTypeSponsored,
  isRegistrationTypeVisitor,
  isRegistrationTypeVisitorOrRental,
  isVehicleTypeMotorcycle,
  noSpecialCharacterAndSpaces,
  stringsToComboBoxAdapter,
} from '../../../utils';
import {IVehicleDetailsProps} from './VehicleDetails.types';

export const VehicleDetails = ({isFormAdmin, setShowChaufferSection}: IVehicleDetailsProps): JSX.Element => {
  const {rootDivStyle, twoColumnStyle, hTextStyle, hdivStyle} = VehicleRootStyles;
  const formFields: IRegisteredVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const vehicleFormError: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const vehicleformState: IFormState = useSelector((state: RootState) => state.formState);
  const isFormEdit = vehicleformState.isFormEdit;
  const todayDate = dayjs(new Date()).format('MM/DD/YYYY');
  const dispatch = useDispatch();
  const user: IUser = useSelector((state: RootState) => state.user);
  const aliasName = user.userAlias;
  const userFirstName = user.firstName;
  const userLastName = user.lastName;
  const {data: configData = {}} = useGetConfigQuery(regionId);
  const colorsList = configData[ConfigKey.ColorList] as string;
  const registrationTypesList = configData[ConfigKey.RegistrationTypeList] as string;
  const stateList = configData[ConfigKey.StateList] as string;
  const vehicleTypeList = configData[ConfigKey.VehicleTypeList] as string;
  const parkingLocationList = configData[ConfigKey.LocationList] as string;
  const licenseMaxLength = Number(configData[ConfigKey.LicenseMaxLength] as string);
  const permitMaxLength = Number(configData[ConfigKey.LicenseMaxLength] as string);

  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(VehicleDetailsUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(VehicleDetailsUsageEvents.root);
    };
  }, [telemetryClient]);

  const handleInputChange = (event: any, fieldName: any, options?: any) => {
    event.preventDefault();
    setShowChaufferSection && setShowChaufferSection(true);
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    options && options.text
      ? dispatch(setVehicleDetails({...formFields, [fieldName]: options.text}))
      : !(
          (fieldName === vehicleDetail.licensePlate.name && !noSpecialCharacterAndSpaces(event.target.value)) ||
          (fieldName === vehicleDetail.permitNumber.name && !noSpecialCharacterAndSpaces(event.target.value))
        ) && dispatch(setVehicleDetails({...formFields, [fieldName]: event.target.value}));
    if (options) {
      if (isVehicleTypeMotorcycle(options.text)) {
        setShowChaufferSection && setShowChaufferSection(false);
        dispatch(
          setVehicleDetails({...formFields, model: emptyString, vehicleType: Motorcycle, isCarpoolChecked: false}),
        );
        dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
      } else if (isRegistrationTypeVisitor(options.text)) {
        dispatch(setVehicleDetails({...formFields, isCarpoolChecked: false, [fieldName]: options.text}));
        dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
      }
    }
  };

  const onNewLicenseCheckChange = (event: any) => {
    const checked = event.target.checked;
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    dispatch(
      setVehicleDetails({
        ...formFields,
        licensePlate: checked ? vehicleDetail.licensePlate.newLicensePlate : '',
        isNewLicenseChecked: checked,
      }),
    );
  };
  useEffect(() => {
    dispatch(
      setVehicleDetails({
        ...formFields,
        ownerFirstName:
          isRegistrationTypeSponsored(formFields.registrationType) || isFormAdmin
            ? formFields.ownerFirstName
            : userFirstName,
        ownerLastName:
          isRegistrationTypeSponsored(formFields.registrationType) || isFormAdmin
            ? formFields.ownerLastName
            : userLastName,
        firstName: isFormAdmin ? formFields.firstName : userFirstName,
        lastName: isFormAdmin ? formFields.firstName : userLastName,
        userAlias: isFormAdmin ? formFields.userAlias : aliasName,
        registrationDate: isFormEdit ? formFields.registrationDate : todayDate,
        vehicleType: formFields.vehicleType === 'Unknown' ? emptyString : formFields.vehicleType,
        year: !Number(formFields.year) ? emptyString : formFields.year,
        permitNumber:
          formFields.permitNumber === vehicleDetail.permitNumber.valueVisitor ||
          formFields.permitNumber === vehicleDetail.permitNumber.valueUnassigned
            ? isRegistrationTypeVisitor(formFields.registrationType)
              ? vehicleDetail.permitNumber.valueVisitor
              : vehicleDetail.permitNumber.valueUnassigned
            : (formFields.permitNumber === emptyString || formFields.permitNumber === '-1') && !isFormAdmin
            ? vehicleDetail.permitNumber.valueUnassigned
            : formFields.permitNumber,
        expireInDays: !isRegistrationTypeVisitorOrRental(formFields.registrationType)
          ? undefined
          : Math.max(1, parseInt(formFields.expireInDays ?? '0')).toString(),
      }),
    );
  }, [aliasName, dispatch, formFields, isFormAdmin, isFormEdit, todayDate, userFirstName, userLastName]);

  return (
    <div>
      <Stack className={hdivStyle}>
        <Text className={hTextStyle}>{vehicleDetail.headerText}</Text>
      </Stack>
      <Stack horizontal className={rootDivStyle}>
        <SelectBox
          label={vehicleDetail.vehicleType.label}
          placeholder={vehicleDetail.vehicleType.placeHolder}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.vehicleType.name, option);
            vehicleFormError.isVehicleTypeError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isVehicleTypeError: false}));
            option?.text === Motorcycle &&
              vehicleFormError.isModelError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isModelError: false}));
          }}
          options={stringsToComboBoxAdapter(vehicleTypeList)}
          text={formFields.vehicleType}
          allowFreeform={false}
          errorMessage={
            vehicleFormError.isFormSubmit && vehicleFormError.isVehicleTypeError ? EmptySpaceString : emptyString
          }
          required
        />
        <InputField
          inputType={'text'}
          name={vehicleDetail.make.name}
          placeholder={vehicleDetail.make.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.make.name);
            vehicleFormError.isMakeError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isMakeError: false}));
          }}
          value={formFields.make}
          required
          validateOnLoad={false}
          IconLabel={{
            title: vehicleDetail.make.title,
            icon: vehicleDetail.make.icon,
            label: vehicleDetail.make.label,
          }}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isMakeError ? EmptySpaceString : emptyString}
        />
        <InputField
          inputType={'text'}
          name={vehicleDetail.model.name}
          placeholder={vehicleDetail.model.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.model.name);
            vehicleFormError.isModelError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isModelError: false}));
          }}
          value={isVehicleTypeMotorcycle(formFields.vehicleType) ? emptyString : formFields.model}
          validateOnLoad={false}
          IconLabel={{
            title: vehicleDetail.model.title,
            icon: vehicleDetail.model.icon,
            label: vehicleDetail.model.label,
          }}
          required={!isVehicleTypeMotorcycle(formFields.vehicleType)}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isModelError ? EmptySpaceString : emptyString}
          disabled={isVehicleTypeMotorcycle(formFields.vehicleType)}
        />
      </Stack>
      <Stack horizontal className={rootDivStyle}>
        <SelectBox
          label={vehicleDetail.year.label}
          placeholder={vehicleDetail.year.placeHolder}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.year.name, option);
            vehicleFormError.isYearError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isYearError: false}));
          }}
          text={formFields.year}
          options={optionsVehicleRegistration.optionsYear}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isYearError ? EmptySpaceString : emptyString}
          required
        />
        <SelectBox
          placeholder={vehicleDetail.color.placeHolder}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.color.name, option);
            vehicleFormError.isColorError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isColorError: false}));
          }}
          text={formFields.color}
          options={stringsToComboBoxAdapter(colorsList)}
          IconLabel={{
            title: vehicleDetail.color.title,
            icon: vehicleDetail.color.icon,
            label: vehicleDetail.color.label,
          }}
          iconButtonProps={{ariaLabel: vehicleDetail.color.title}}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isColorError ? EmptySpaceString : emptyString}
          required
        />
        <Stack>
          <InputField
            inputType={'text'}
            maxLength={licenseMaxLength}
            name={vehicleDetail.licensePlate.name}
            placeholder={vehicleDetail.licensePlate.placeHolder}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              handleInputChange(event, vehicleDetail.licensePlate.name);
              vehicleFormError.isLicensePlateError &&
                dispatch(setVehicleRegisterError({...vehicleFormError, isLicensePlateError: false}));
            }}
            value={formFields.licensePlate}
            required
            validateOnLoad={false}
            IconLabel={{
              title: vehicleDetail.licensePlate.title,
              icon: vehicleDetail.licensePlate.icon,
              label: vehicleDetail.licensePlate.label,
            }}
            errorMessage={
              vehicleFormError.isFormSubmit && vehicleFormError.isLicensePlateError ? EmptySpaceString : emptyString
            }
            disabled={formFields.isNewLicenseChecked}
          />
          <Checkbox
            styles={newLicenseCheckBoxStyles}
            data-testid="newLicenseCheck"
            label={vehicleDetail.newLicenseCheckbox.label}
            onChange={event => onNewLicenseCheckChange(event)}
            checked={formFields.isNewLicenseChecked}
          />
        </Stack>
      </Stack>
      <Stack horizontal className={rootDivStyle}>
        <SelectBox
          label={vehicleDetail.stateProvince.label}
          placeholder={vehicleDetail.stateProvince.placeHolder}
          required
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.stateProvince.name, option);
            vehicleFormError.isStateError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isStateError: false}));
          }}
          text={formFields.state}
          options={stringsToComboBoxAdapter(stateList)}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isStateError ? EmptySpaceString : emptyString}
        />
        <SelectBox
          id="test"
          label={vehicleDetail.parkingLocation.label}
          placeholder={vehicleDetail.parkingLocation.placeHolder}
          required
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.parkingLocation.name, option);
            vehicleFormError.isParkingLocationError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isParkingLocationError: false}));
          }}
          text={formFields.parkingLocation}
          options={stringsToComboBoxAdapter(parkingLocationList)}
          errorMessage={
            vehicleFormError.isFormSubmit && vehicleFormError.isParkingLocationError ? EmptySpaceString : emptyString
          }
        />
        <SelectBox
          label={vehicleDetail.registrationType.label}
          placeholder={vehicleDetail.registrationType.placeHolder}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.registrationType.name, option);
            vehicleFormError.isRegistrationTypeError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isRegistrationTypeError: false}));
          }}
          required
          text={formFields.registrationType}
          options={stringsToComboBoxAdapter(registrationTypesList)}
          errorMessage={
            vehicleFormError.isFormSubmit && vehicleFormError.isRegistrationTypeError ? EmptySpaceString : emptyString
          }
          disabled={isFormAdmin}
        />
      </Stack>
      <Stack horizontal className={twoColumnStyle}>
        {(isFormEdit || isFormAdmin) && (
          <InputField
            inputType={'text'}
            name={vehicleDetail.permitNumber.name}
            placeholder={vehicleDetail.permitNumber.placeHolder}
            maxLength={permitMaxLength}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              handleInputChange(event, vehicleDetail.permitNumber.name);
            }}
            value={formFields.permitNumber}
            required={false}
            disabled={isFormAdmin ? false : true}
            validateOnLoad={false}
            IconLabel={{
              title: vehicleDetail.permitNumber.title,
              icon: vehicleDetail.permitNumber.icon,
              label: vehicleDetail.permitNumber.label,
            }}
          />
        )}

        {isRegistrationTypeVisitorOrRental(formFields.registrationType) ? (
          <SelectBox
            label={vehicleDetail.expiresAfter.label}
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
              handleInputChange(event, vehicleDetail.expiresAfter.name, option);
            }}
            options={optionsVehicleRegistration.optionExpireAfter(1, 30)}
            text={!!formFields.expireInDays ? formFields.expireInDays.toString() : '1'}
            disabled={
              (isFormEdit && !isRegistrationTypeVisitorOrRental(formFields.registrationType)) || isFormAdmin
                ? true
                : false
            }
          />
        ) : (
          <></>
        )}
      </Stack>
      {isFormEdit && (
        <Stack horizontal className={twoColumnStyle}>
          <InputField
            inputType="text"
            name={vehicleDetail.issuedDate.name}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {}}
            placeholder={vehicleDetail.issuedDate.placeHolder}
            value={dayjs(formFields.registrationDate).format('MM/DD/YYYY')}
            required={false}
            validateOnLoad={false}
            disabled={true}
            IconLabel={{
              label: vehicleDetail.issuedDate.placeHolder,
              title: emptyString,
              icon: emptyString,
            }}
          />
        </Stack>
      )}
    </div>
  );
};
