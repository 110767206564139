import {IComboBoxOption} from '@fluentui/react/lib/components/ComboBox/ComboBox.types';
import {ICarpoolData, msEmployee, msDomain, IRegisteredVehicleDataItem} from '@microsoft/smart-parking-coreui';
import {Motorcycle, emptyString, vehicleDetail} from '../configs';

export const textFieldValidate = (value: string): string => {
  if (!value.match(/^[a-zA-Z]+$/)) return 'Input value must contain letters only';
  else return emptyString;
};
export const selectBoxDataArray = (keyLength: number, initialValue: number, isIncremental: boolean) => {
  let incrementValue = isIncremental ? 1 : -1;
  const resultArray = [];
  for (let i = 0; i < keyLength; i++) {
    resultArray.push({key: i, text: `${initialValue + i * incrementValue}`});
  }
  return resultArray;
};
const numberObj = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};
export const getNumberChar = (number: number) => {
  if (number === 1 || number === 2 || number === 3) {
    return number + numberObj[number];
  } else {
    return number + 'th';
  }
};
export const isValidEmail = (email?: string) => {
  return email ? /\S+@\S+\.\S+/.test(email) : false;
};
export const isAliasFormatValid = (alias: string) => {
  return alias.includes('@') ? false : true;
};
export const isEmailMicrosoft = (email: string) => email.endsWith('@microsoft.com');

export const createCCForCarpoolMail = (carpoolDetails?: ICarpoolData) => {
  return carpoolDetails
    ? carpoolDetails.carpoolMembers
        .map(member => (member.memberType === msEmployee ? member.memberAlias + msDomain : member.email))
        .toString()
    : emptyString;
};

export const stringsToComboBoxAdapter = (list: string): IComboBoxOption[] => {
  const stringArray = list?.split(',').map(value => value.trim());
  let index = 0;
  const comboBoxOptions: IComboBoxOption[] = [];
  stringArray?.forEach((current: string) => {
    comboBoxOptions.push({key: index++, text: current});
  });
  return comboBoxOptions;
};
export const noSpecialCharacter = (input: string) => /^[a-zA-Z0-9\s]*$/.test(input);
export const noSpecialCharacterAndSpaces = (input: string) => /^[a-zA-Z0-9]*$/.test(input);
export const onlyHasAllowedChars = (input: string) => /^[a-zA-Z0-9-]*$/.test(input);

export const isRegistrationTypeVisitorOrRental = (registrationType: string) =>
  registrationType === vehicleDetail.registrationType.valueVisitor ||
  registrationType === vehicleDetail.registrationType.valueRental;
  
export const isRegistrationTypeVisitor = (registrationType: string) =>
  registrationType === vehicleDetail.registrationType.valueVisitor;

export const isRegistrationTypeSponsored = (registrationType: string) =>
  registrationType === vehicleDetail.registrationType.valueSponsored;

export const isVehicleTypeMotorcycle = (vehicleType: string) => vehicleType === Motorcycle;

export const emailReceiverAlias = (vehicleRegistrations: IRegisteredVehicleDataItem[], registrationId: string) => {
  return vehicleRegistrations.find((item: {registrationId: string}) => item.registrationId === registrationId)
    ?.userAlias;
};

export const maskLicensePlate = (registrations: IRegisteredVehicleDataItem[]) => {
  return registrations.map(registration => {
    const copyRegistration = {...registration};
    const visibleChars = Math.ceil(copyRegistration.licensePlate.length / 2);
    const maskedChars = '*'.repeat(copyRegistration.licensePlate.length - visibleChars);
    copyRegistration.licensePlate = maskedChars + registration.licensePlate.slice(-visibleChars);
    return copyRegistration;
  });
};
