import React, {useCallback, useEffect, useState} from 'react';
import {loadTheme, SpinnerSize, ThemeProvider} from '@fluentui/react';
import {SiteHeader, SiteFooter, SiteStyles, ErrorBadge, InfoBadge, SpinLoader, DataGridStyles} from '../components';
import AppRoute from '../route/AppRoute';
import {SParkTheme} from '../theme';
import {useDispatch, useSelector} from 'react-redux';
import {TelemetryConstants} from '../configs';
import {MessageBadgeUsageEvents, PageUsageEvents} from '../configs/usageEvents';
import {
  RootState,
  IIsCarpoolError,
  authHelper,
  ICarpoolData,
  IRegisterVehicleErrorItem,
  IMessageBadge,
  IRegisterVehicleDataItem,
  getTelemetryClient,
  setCarpoolError,
  setUser,
  setVehicleRegisterError,
  setInfoBadgeState,
  setEnvironment,
  useGetConfigQuery,
  regionId,
} from '@microsoft/smart-parking-coreui';
import Config, {spinnerMessage} from '../configs/constants/uiConfig';
import {IIdTokenClaims} from './App.types';
import useCarpoolValidation from '../customHooks/carpoolErrorValidations/carpoolErrorValidation';

export const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  loadTheme(SParkTheme);
  useEffect(() => {
    dispatch(setEnvironment(Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT));
  }, [dispatch]);
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const errorState: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const vehicleDetails: IRegisterVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const carpoolChecked = vehicleDetails.isCarpoolChecked;
  const apiResponse: IMessageBadge = useSelector((state: RootState) => state.infoBadge);
  const isCarpoolErrorState: IIsCarpoolError = useSelector((state: RootState) => state.isCarpoolError);
  const [enableAdmin, setEnableAdmin] = useState(false);
  const {isLoading: isConfigLoading, isError: isConfigError, isSuccess: isConfigSuccess} = useGetConfigQuery(regionId);
  const {carpoolErrors} = useCarpoolValidation();

  const isCarpoolError =
    carpoolErrors.some(
      v =>
        v.memberType ||
        v.originCity ||
        v.memberAlias ||
        v.email ||
        v.aliasConflict ||
        v.emailConflict ||
        v.emailConflictWithAlias,
    ) ||
    (carpoolChecked && (!carpoolDetails?.carpoolPolicyChecked || carpoolDetails?.carpoolMembers.length < 2));

  useEffect(() => {
    dispatch(setCarpoolError({...isCarpoolErrorState, isCarpoolError: isCarpoolError ?? false}));
  }, [dispatch, isCarpoolError, isCarpoolErrorState]);

  const stableDispatch = useCallback((args: any) => dispatch(args), [dispatch]);

  /*istanbul ignore next*/
  useEffect(() => {
    telemetryClient.startTrackPage(PageUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(PageUsageEvents.root, window.location.href);
    };
  }, [telemetryClient]);

  useEffect(() => {
    async function fetchGraphDetails() {
      const authResult = await authHelper.getGraphApiAccessToken();
      const idTokenClaims = authResult.idTokenClaims as IIdTokenClaims;
      const matchingClaims = Config.REACT_APP_MSAL_ADMIN_ROLES.filter((role: string) =>
        idTokenClaims['roles']?.includes(role),
      );
      matchingClaims.length > 0 && setEnableAdmin(true);
      const userDetails = await authHelper.getMsGraphDetails(authResult.accessToken);
      stableDispatch(
        setUser({
          firstName: userDetails.givenName,
          lastName: userDetails.surname,
          userAlias: userDetails.userPrincipalName?.substring(0, userDetails.userPrincipalName?.indexOf('@')),
          country: userDetails.usageLocation
        }),
      );
    }
    fetchGraphDetails();
  }, [stableDispatch, enableAdmin]);

  const {
    isFirstNameError,
    isLastNameError,
    isMakeError,
    isModelError,
    isColorError,
    isYearError,
    isVehicleTypeError,
    isLicensePlateError,
    isRegistrationTypeError,
    isStateError,
    isParkingLocationError,
    isFormSubmit,
    isPolicyCheckedError,
    closeModal,
  } = errorState;
  const isFormError =
    isFirstNameError ||
    isLastNameError ||
    isMakeError ||
    isModelError ||
    isColorError ||
    isYearError ||
    isVehicleTypeError ||
    isLicensePlateError ||
    isStateError ||
    isRegistrationTypeError ||
    isParkingLocationError ||
    isPolicyCheckedError ||
    isCarpoolError;

  const {response, message, responseStatus, closeStatusModal} = apiResponse;

  const onDismiss = () => {
    telemetryClient.startTrackEvent(MessageBadgeUsageEvents.dismissButtonClicked);
    dispatch(setVehicleRegisterError({...errorState, closeModal: true}));
    dispatch(
      setInfoBadgeState({
        closeStatusModal: true,
        response: false,
        responseStatus: '',
        message: '',
      }),
    );
    telemetryClient.stopTrackEvent(MessageBadgeUsageEvents.dismissButtonClicked);
  };

  return (
    <ThemeProvider {...SParkTheme}>
      {isConfigLoading ? (
        <SpinLoader
          size={SpinnerSize.large}
          className={DataGridStyles.spinnerStyle}
          label={spinnerMessage.app.loading}
        />
      ) : isConfigError ? (
        <text>{spinnerMessage.app.error}</text>
      ) : (
        isConfigSuccess && (
          <>
            <SiteStyles />
            <SiteHeader />
            <main id="main" tabIndex={-1}>
              {!closeModal && isFormSubmit && isFormError ? (
                <ErrorBadge
                  isFirstNameError={isFirstNameError}
                  isLastNameError={isLastNameError}
                  isMakeError={isMakeError}
                  isModelError={isModelError}
                  isLicensePlateError={isLicensePlateError}
                  isVehicleTypeError={isVehicleTypeError}
                  isRegistrationTypeError={isRegistrationTypeError}
                  isStateError={isStateError}
                  isColorError={isColorError}
                  isYearError={isYearError}
                  isParkingLocationError={isParkingLocationError}
                  isParkingPolicyCheckedError={isPolicyCheckedError}
                  onDismiss={onDismiss}
                  isCarpoolError={isCarpoolError ?? false}
                  carpoolErrors={carpoolErrors}
                  carpoolPolicyCheckedError={!carpoolDetails?.carpoolPolicyChecked}
                  carpoolCounterError={carpoolDetails?.carpoolMembers.length < 2}
                />
              ) : (
                !closeStatusModal &&
                response && <InfoBadge messageText={message} messageStatus={responseStatus} onDismiss={onDismiss} />
              )}
              <AppRoute isAdminEnabled={enableAdmin} />
            </main>
            <SiteFooter />
          </>
        )
      )}
    </ThemeProvider>
  );
};
