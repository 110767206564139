import {
    IStyle,
    mergeStyleSets,
    getScreenSelector,
    ScreenWidthMinLarge,
    ScreenWidthMaxLarge,
    ScreenWidthMinSmall,
    ScreenWidthMaxSmall,
    ScreenWidthMinMedium,
    ScreenWidthMaxMedium,
    ScreenWidthMinXLarge,
    ScreenWidthMaxXLarge,
    ScreenWidthMinXXLarge,
    ScreenWidthMaxXXLarge,
    ScreenWidthMinXXXLarge,
  } from '@fluentui/react';
  
  export const ChaufferRootStyles = mergeStyleSets({
    rootDivStyle: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      marginLeft: '1%',
      marginRight: '20%',
      paddingBottom: 20,
      selectors: {
        [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
          marginRight: '8%',
        },
      },
    } as IStyle,
  
    chaufferDrpStyle: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      marginLeft: '1%',
      marginRight: '50%',
      paddingBottom: 20,
      selectors: {
        [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
          marginRight: '41%',
        },
        [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
          marginRight: '50%',
        },
        [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
          marginRight: '52.5%',
        },
      },
    } as IStyle,
  
    twoColumnStyle: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      marginLeft: '1%',
      marginRight: '50%',
      paddingBottom: 20,
      selectors: {
        [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
          marginRight: '8%',
        },
        [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
          marginRight: '41%',
        },
        [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
          marginRight: '50%',
        },
        [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
          marginRight: '52.5%',
        },
      },
    } as IStyle,
  
    hdivStyle: {
      alignItems: 'flex-start',
      marginTop: '10px',
      marginBottom: '10px',
      marginLeft: '1%',
    } as IStyle,
  
    drpHidden: {
      visibility: 'hidden',
    } as IStyle,
  
    dateStyle: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
      margin: '10px 0px 0px 10px',
      marginLeft: '1%',
    } as IStyle,
    hTextStyle: {
      fontSize: 18,
      fontWeight: 500,
    } as IStyle,
  });