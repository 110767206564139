import {Stack} from '@fluentui/react';
import {ChaufferRootStyles} from './ChaufferDetails.styles';
import {InputField} from '../../common';
import {EmptySpaceString, chaufferDetails, emptyString} from '../../../configs';
import {useDispatch, useSelector} from 'react-redux';
import {
  RootState,
  IRegisterVehicleErrorItem,
  setVehicleDetails,
  setRegisterFormState,
  setVehicleRegisterError,
} from '@microsoft/smart-parking-coreui';
import {noSpecialCharacter} from '../../../utils';

export const ChaufferDetails = (): JSX.Element => {
  const {rootDivStyle} = ChaufferRootStyles;
  const vehicleFormFields = useSelector((state: RootState) => state.vehicleDetails);
  const vehicleFormError: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const dispatch = useDispatch();
  const isFormEdit = useSelector((state: RootState) => state.formState.isFormEdit);
  const contactNumberMaxLength = 10;

  const handleInputChange = (event: any, fieldName: any) => {
    event.preventDefault();
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    noSpecialCharacter(event.target.value) &&
      dispatch(setVehicleDetails({...vehicleFormFields, [fieldName]: event.target.value}));
  };

  return (
    <div>
      <Stack horizontal className={rootDivStyle}>
        <InputField
          inputType={'text'}
          name={chaufferDetails.chaufferName.name}
          placeholder={chaufferDetails.chaufferName.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, chaufferDetails.chaufferName.name);
            dispatch(setVehicleRegisterError({...vehicleFormError, isChaufferNameError: false}));
          }}
          value={vehicleFormFields.chaufferName}
          required={false}
          validateOnLoad={false}
          IconLabel={{
            title: emptyString,
            icon: emptyString,
            label: chaufferDetails.chaufferName.label,
          }}
          errorMessage={
            vehicleFormError.isFormSubmit && !vehicleFormFields.chaufferName ? EmptySpaceString : emptyString
          }
        />
        <InputField
          inputType={'text'}
          name={chaufferDetails.drivingLicense.name}
          placeholder={chaufferDetails.drivingLicense.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, chaufferDetails.drivingLicense.name);
            vehicleFormError.isChaufferDrivingLicenseError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isChaufferDrivingLicenseError: false}));
          }}
          value={vehicleFormFields.chaufferDrivingLicense}
          required={false}
          validateOnLoad={false}
          IconLabel={{
            title: emptyString,
            icon: emptyString,
            label: chaufferDetails.drivingLicense.label,
          }}
          errorMessage={
            vehicleFormError.isFormSubmit && !vehicleFormFields.chaufferDrivingLicense ? EmptySpaceString : emptyString
          }
        />
        <InputField
          inputType={'number'}
          name={chaufferDetails.contactNumber.name}
          placeholder={chaufferDetails.contactNumber.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, chaufferDetails.contactNumber.name);
            vehicleFormError.isChaufferContactNumberError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isChaufferContactNumberError: false}));
          }}
          value={vehicleFormFields.chaufferContactNumber}
          required={false}
          validateOnLoad={false}
          IconLabel={{
            title: emptyString,
            icon: emptyString,
            label: chaufferDetails.contactNumber.label,
          }}
          maxLength={contactNumberMaxLength}
          errorMessage={
            vehicleFormError.isFormSubmit && !vehicleFormFields.chaufferContactNumber ? EmptySpaceString : emptyString
          }
        />
      </Stack>
    </div>
  );
};
