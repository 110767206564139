/* istanbul ignore file */
import {PrimaryButton, SpinnerSize} from '@fluentui/react';
import Config, {
  mainPanelConst,
  Motorcycle,
  ConfigKey,
  infoBadgeDisplayMessage,
  carpoolInfoMessage,
  spinnerMessage,
} from '../../../configs/constants/uiConfig';
import {useDispatch, useSelector} from 'react-redux';
import {DataGridStyles, SpinLoader} from '../..';
import {IRegistrationButtonProps, registerButtonStyle} from '.';
import {createCCForCarpoolMail} from '../../../utils';
import {
  RootState,
  ICarpoolData,
  IIsCarpoolError,
  usePostVehicleDetailsMutation,
  setVehicleRegisterError,
  vehicleRegistrationUitoServiceAdapter,
  setInfoBadgeState,
  setVehicleDetails,
  vehicleRegistrationInitialState,
  msDomain,
  createCarpoolMailBody,
  createCarpoolMailSubject,
  mailTypeCarpool,
  resetCarpool,
  carpoolInitialState,
  usePostCarpoolMutation,
  usePostEmailMutation,
  IRegistrationPostService,
  IRegisteredVehicleDataItem,
  useGetConfigQuery,
  regionId,
  getTelemetryClient,
} from '@microsoft/smart-parking-coreui';
import {TelemetryConstants} from '../../../configs';
import {RegisterButtonUsageEvents} from '../../../configs/usageEvents/components/form/RegisterButtonUsageEvents';

export const RegisterButton = ({userAlias, isLoading, isFetching, refetch}: IRegistrationButtonProps): JSX.Element => {
  const {root} = registerButtonStyle;
  const vehicleFields: IRegisteredVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const carpoolErrorState: IIsCarpoolError = useSelector((state: RootState) => state.isCarpoolError);
  const dispatch = useDispatch();
  const [createPost] = usePostVehicleDetailsMutation();
  const [createCarpoolPost] = usePostCarpoolMutation();
  const [sendCarpoolEmail] = usePostEmailMutation();
  const {data: configData = {}} = useGetConfigQuery(regionId);
  const isAnyFieldEdited = useSelector((state: RootState) => state.formState.isAnyFieldEdited);
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const handleRegisterClick = async (): Promise<boolean> => {
    const validationError = {
      isFirstNameError: !vehicleFields.ownerFirstName,
      isLastNameError: !vehicleFields.ownerLastName,
      isVehicleTypeError: !vehicleFields.vehicleType,
      isMakeError: !vehicleFields.make,
      isModelError: vehicleFields.vehicleType !== Motorcycle && !vehicleFields.model,
      isColorError: !vehicleFields.color,
      isYearError: !vehicleFields.year,
      isLicensePlateError: !vehicleFields.licensePlate,
      isStateError: !vehicleFields.state,
      isRegistrationTypeError: !vehicleFields.registrationType,
      isParkingLocationError: !vehicleFields.parkingLocation,
      isPolicyCheckedError: !vehicleFields.isParkingPolicyChecked,
      isCarpoolError: carpoolErrorState.isCarpoolError,
      isChaufferContactNumberError: false,
      isChaufferDrivingLicenseError: false,
      isChaufferNameError: false,
      isCountryError: false
    };
    dispatch(
      setVehicleRegisterError({
        ...validationError,
        isFormSubmit: true,
        closeModal: false,
      }),
    );
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    if (validateForm(validationError)) {
      try {
        const response: IRegistrationPostService = await createPost({
          apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
          regionId: regionId,
          apiRegistrationUrlSubString: configData[ConfigKey.VehicleRegistrationUrl] as string,
          postRequestData: vehicleRegistrationUitoServiceAdapter({...vehicleFields}),
        }).unwrap();
        telemetryClient.trackEvent(RegisterButtonUsageEvents.CreateRegistrationSuccess, {url: window.location.href});
        refetch();
        dispatch(
          setInfoBadgeState({
            response: true,
            responseStatus: 'success',
            message: infoBadgeDisplayMessage.create.success,
            closeStatusModal: false,
          }),
        );
        dispatch(
          setVehicleRegisterError({
            isFormSubmit: false,
            isVehicleTypeError: false,
            isMakeError: false,
            isModelError: false,
            isColorError: false,
            isYearError: false,
            isLicensePlateError: false,
            isStateError: false,
            isRegistrationTypeError: false,
            isParkingLocationError: false,
            isPolicyCheckedError: false,
            closeModal: false,
            isFirstNameError: false,
            isLastNameError: false,
            isChaufferContactNumberError: false,
            isChaufferDrivingLicenseError: false,
            isChaufferNameError: false,
            isCountryError: false
          }),
        );
        vehicleFields.isCarpoolChecked &&
          (await handleCarpoolCreation(response.registrationId, vehicleFields.licensePlate));
        dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialvehicleRegistrationState[0]));
        return true;
      } catch (error: any) {
        telemetryClient.trackEvent(RegisterButtonUsageEvents.CreateRegistrationFailure, {url: window.location.href});
        if (error.data && error.data.message === infoBadgeDisplayMessage.duplicate.apiError) {
          dispatch(
            setInfoBadgeState({
              response: true,
              responseStatus: 'error',
              message: infoBadgeDisplayMessage.duplicate.uiError,
              closeStatusModal: false,
            }),
          );
          telemetryClient.trackEvent(RegisterButtonUsageEvents.DuplicateRegistrationFailure, {
            url: window.location.href,
          });
        } else {
          dispatch(
            setInfoBadgeState({
              response: true,
              responseStatus: 'error',
              message: infoBadgeDisplayMessage.update.error,
              closeStatusModal: false,
            }),
          );
          telemetryClient.trackException({exception: error as Error}, {url: window.location.href});
        }
      }
    }
    return false;
  };

  const validateForm = (error: any) =>
    error.isFirstNameError === false &&
    error.isLastNameError === false &&
    error.isVehicleTypeError === false &&
    error.isMakeError === false &&
    error.isModelError === false &&
    error.isColorError === false &&
    error.isYearError === false &&
    error.isLicensePlateError === false &&
    error.isStateError === false &&
    error.isRegistrationTypeError === false &&
    error.isParkingLocationError === false &&
    error.isPolicyCheckedError === false &&
    error.isCarpoolError === false;

  const sendCarpoolCreateEmail = async () => {
    try {
      await sendCarpoolEmail({
        emailApiBaseUrl: configData[ConfigKey.CarpoolEmailUrl] as string,
        regionId: regionId,
        requestData: {
          to: userAlias + msDomain,
          emailBody: createCarpoolMailBody(vehicleFields.firstName ?? '', vehicleFields.lastName ?? ''),
          subject: createCarpoolMailSubject,
          cc: createCCForCarpoolMail(carpoolDetails),
          mailType: mailTypeCarpool,
        },
      }).unwrap();
      telemetryClient.trackEvent(RegisterButtonUsageEvents.CreateCarpoolEmailSuccess, {url: window.location.href});
    } catch (error) {
      telemetryClient.trackEvent(RegisterButtonUsageEvents.CreateCarpoolEmailFailure, {url: window.location.href});
      telemetryClient.trackException({exception: error as Error}, {url: window.location.href});
    }
  };

  const handleCarpoolCreation = async (registrationId: string, licensePlate: string) => {
    if (registrationId) {
      try {
        await createCarpoolPost({
          carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
          carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl] as string,
          regionId: regionId,
          requestData: {
            ...carpoolDetails,
            groupOwnerAlias: userAlias,
            id: registrationId,
            licensePlate,
          },
        }).unwrap();
        telemetryClient.trackEvent(RegisterButtonUsageEvents.CreateCarpoolRegistrationSuccess, {
          url: window.location.href,
        });
        dispatch(
          setInfoBadgeState({
            response: true,
            responseStatus: 'success',
            message: carpoolInfoMessage.create.success,
            closeStatusModal: false,
          }),
        );
        dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
        dispatch(
          setVehicleRegisterError({
            isFormSubmit: false,
            isVehicleTypeError: false,
            isMakeError: false,
            isModelError: false,
            isColorError: false,
            isYearError: false,
            isLicensePlateError: false,
            isStateError: false,
            isRegistrationTypeError: false,
            isParkingLocationError: false,
            isPolicyCheckedError: false,
            closeModal: false,
            isFirstNameError: false,
            isLastNameError: false,
            isChaufferContactNumberError: false,
            isChaufferDrivingLicenseError: false,
            isChaufferNameError: false,
            isCountryError: false
          }),
        );
        dispatch(setVehicleDetails({...vehicleFields, isCarpoolChecked: false}));
        await sendCarpoolCreateEmail();
        return true;
      } catch (error) {
        telemetryClient.trackEvent(RegisterButtonUsageEvents.CreateCarpoolRegistrationFailure, {
          url: window.location.href,
        });
        telemetryClient.trackException({exception: error as Error}, {url: window.location.href});
        dispatch(
          setInfoBadgeState({
            response: true,
            responseStatus: 'error',
            message: carpoolInfoMessage.create.error,
            closeStatusModal: false,
          }),
        );
        return false;
      }
    }
  };
  if (isLoading || isFetching) {
    return (
      <SpinLoader
        size={SpinnerSize.xSmall}
        className={DataGridStyles.spinnerStyle}
        label={spinnerMessage.dataGrid.isLoading}></SpinLoader>
    );
  }
  return (
    <>
    <PrimaryButton
      className={root}
      title={mainPanelConst.registerButton.title}
      text={mainPanelConst.registerButton.text}
      onClick={handleRegisterClick}
      disabled={!isAnyFieldEdited}
      data-testid="register-button"
    />
    </>
  );
};
