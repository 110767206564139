import React, {useEffect} from 'react';
import {AuthenticatedTemplate, useMsalAuthentication} from '@azure/msal-react';
import {InteractionType} from '@azure/msal-browser';
import {PageUsageEvents} from '../configs/usageEvents';
import Config from '../configs/constants/uiConfig';
import {getTelemetryClient} from '@microsoft/smart-parking-coreui';
import {TelemetryConstants} from '../configs';

interface MsalAuthProviderProps {
  children: React.ReactNode;
}

const MsalBrowser: React.FC<MsalAuthProviderProps> = ({children}) => {
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(PageUsageEvents.auth);
    return () => {
      telemetryClient.stopTrackPage(PageUsageEvents.auth);
    };
  }, [telemetryClient]);

  useMsalAuthentication(InteractionType.Redirect);
  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

const MsalAuthProvider: React.FC<MsalAuthProviderProps> = (props: MsalAuthProviderProps) => (
  <MsalBrowser>{props.children}</MsalBrowser>
);

export default MsalAuthProvider;
