import {CoherenceDataGridColumn} from '@coherence-design-system/controls';
import {ContextualMenu, DialogType, IDialogContentProps} from '@fluentui/react';
import {IUserDetails} from '../../components/form/DataGrid';
import environmentVariables from '../../env';
import {IAdminSearchParamInputState} from '../../pages/AdminScreen/AdminScreen.types';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...environmentVariables,
};

export const siteHeaderProps = {
  SiteHeaderId: 'siteheader',
  ariaLabel: 'Parking',
  headerLabel: 'Parking',
};
export const siteHeaderLabel = {
  linkName: 'headericon',
  imageAlt: 'headerLogo',
  headerLabel: 'Global Security',
};
export const siteFooterText = {
  imageAlt: 'footerLogo',
};
export const EditVehicleDetailsLabels = {
  title: 'Save',
  text: 'Save Changes',
  testid: 'footerbutton',
  defaultButtonText: 'Cancel',
  headerText: 'Edit Vehicle',
};
export const helpLabels = {
  parkingRegResFAQ: {
    needHelpText: `Need Help? For assistance with Parking programs or operations in your region, visit `,
    faqsLinkText: 'Parking Registration Resources & FAQs',
  },
  msMcommute: {
    pugetRegionText:
      'If you are in the Puget Sound region and have questions about parking operations or policies, reach to Microsoft Commute in the Puget Sound at ',
    mslinkText: 'aka.ms/Mcommute.',
  },
  privacyStatement: {
    linkText: 'Privacy Statement',
  },
};
export const ownerDetailsConst = {
  headerText: 'Driver Information',
  ownerFirstName: {
    name: 'firstName',
    placeHolder: 'Enter First Name',
    label: 'First Name',
  },
  ownerLastName: {
    name: 'lastName',
    placeHolder: 'Enter Last Name',
    label: 'Last Name',
  },
  userAlias: {
    name: 'userAlias',
    placeHolder: 'Enter Sponsor / Contract / Alias',
    label: 'Sponsor / Contract / Alias',
  },
};
export const vehicleDetail = {
  headerText: 'Vehicle Information',
  vehicleOwnerFirstName: {
    name: 'ownerFirstName',
  },
  vehicleOwnerLastName: {
    name: 'ownerLastName',
  },
  vehicleType: {
    placeHolder: 'Select Type of Vehicle',
    label: 'Vehicle Type',
    name: 'vehicleType',
  },
  make: {
    name: 'make',
    placeHolder: 'Enter Make of your vehicle',
    title: `Enter make of your vehicle eg: \n Hyundai, Maruti, BMW, Audi`,
    icon: 'InfoSolid',
    label: 'Make',
  },
  model: {
    name: 'model',
    placeHolder: 'Enter Vehicle Model',
    title: 'Enter model of your vehicle',
    icon: 'InfoSolid',
    label: 'Model',
  },
  year: {
    placeHolder: 'Select Model Year',
    label: 'Year',
    name: 'year',
  },
  color: {
    name: 'color',
    placeHolder: 'Select Vehicle Color',
    title: 'Enter the color closest to the dominant color of your vehicle',
    icon: 'InfoSolid',
    label: 'Color',
  },
  licensePlate: {
    name: 'licensePlate',
    placeHolder: 'Enter License Number',
    title: `1. New Car with no license plates ? \nSelect "New License Plate" checkbox below.\nAll vehicles registered with license\nplates denoted as ‘NEW’ will\nautomatically expire 45 days after\nregistration.\n2. Enter your license plate number\nwithout any spaces`,
    icon: 'InfoSolid',
    label: 'License Plate # ',
    newLicensePlate: 'NEW',
  },
  newLicenseCheckbox: {
    label: 'New License Plate',
  },
  stateProvince: {
    placeHolder: 'Select State / Province',
    label: 'State / Province',
    name: 'state',
  },
  registrationType: {
    placeHolder: 'Select Registration Type',
    label: 'Registration Type',
    name: 'registrationType',
    valueVisitor: 'Visitor',
    valueSponsored: 'Sponsored',
    valueRental: 'Rental',
  },
  parkingLocation: {
    placeHolder: 'Select Parking Location',
    label: 'Parking Location',
    name: 'parkingLocation',
  },
  permitNumber: {
    name: 'permitNumber',
    placeHolder: 'Enter Permit Number',
    title: `For Puget Sound region,Permit \n Number is not required if the \n vehicle is registered as personal.`,
    icon: 'InfoSolid',
    label: 'Permit Number',
    valueUnassigned: 'Unassigned',
    valueVisitor: 'Visitor',
  },
  issuedDate: {
    name: 'registrationDate',
    label: 'Registered date',
    placeHolder: 'Registered date',
  },
  expiresAfter: {
    name: 'expireInDays',
    label: 'Expires after (in days)',
  },
};
export const policyCheckConst = {
  policyCheckText1: 'I have read and understand the',
  policyCheckText2: 'Facilities and Security Vehicle Registration Policy and Procedures',
};
export const mainPanelConst = {
  header: {
    headertext: 'Register a Vehicle',
  },
  registerButton: {
    title: 'Register',
    text: 'Register Vehicle',
  },
};

export const adminPanelConst = {
  header: {
    headerText: 'Search For Registration',
  },
  fieldLabels: {
    searchByAlias: 'Alias',
    searchByLicense: {
      label: 'License Plate #',
      title: 'Please enter license plate number\nwithout any spaces.',
      icon: 'InfoSolid',
    },
    searchByPermit: 'Permit Number',
  },
  fieldPlaceholders: {
    searchByAlias: 'Enter Alias',
    searchByLicense: 'Enter License Plate #',
    searchByPermit: 'Enter Permit Number',
  },
};
export const NavigationPanelConst = {
  header: {
    headertext: 'Vehicle Registration & Management',
  },
  routeNames: {
    userRoute: 'My Vehicles',
    adminRoute: 'Admin',
    parkingInraction: 'Parking Infractions',
    resourceFAQLinkText: 'Parking Registration Resources & FAQs',
  },
};
export const parkingInfraConst = {
  header: {
    headertext: 'No tickets found.',
  },
};
export const carpool = {
  groupMember: {
    placeHolder: 'Select Carpool Group Member',
    label: 'Carpool Group Member',
    name: 'memberType',
  },
  alias: {
    name: 'memberAlias',
    placeHolder: 'Enter Alias',
    label: 'Alias',
    ownerAliasLabel: 'Group owner Alias',
  },
  originCity: {
    name: 'originCity',
    placeHolder: 'Enter Origin City',
    label: 'Origin City',
  },
  permitNumber: {
    name: 'permitNumber',
    placeHolder: 'Carpool Permit Number',
    title: 'PermitNumber',
    icon: 'InfoSolid',
    label: 'Carpool Permit Number',
    description: 'To be completed by issuing party. Not applicable in PS.',
  },
  email: {
    name: 'email',
    placeHolderNonMs: 'Enter Work E-mail',
    placeHolderScoop: 'Enter Scoop E-mail',
    nonMsEmployeeLabel: 'Group member Work E-mail',
    scoopEmployeeLabel: `Driver's Registered Scoop E-mail`,
    ownerEmailLabel: 'Group owner Work E-mail',
  },
  addGroupButtonText: {
    label: ' Add Group Member',
    iconName: 'CircleAdditionSolid',
    title: 'Add Group Member',
    ariaLabel: 'Add',
  },
  carpoolCheckBoxConst: {
    createCarpoollabel: 'Register this vehicle for Carpool?',
    deleteCarpoollabel: 'Register this vehicle for Carpool? (Unchecking this checkbox will delete the carpool group)',
  },
  carpoolPolicyTexts: {
    carpoolPolicyText1: 'I have read and understand the ',
    carpoolPolicyText2: `Microsoft's carpool parking policies`,
  },
  deleteButton: {
    name: 'Delete',
    DisplayName: 'Delete',
    title: 'Delete',
    ariaLabel: 'action',
  },
};
export const gridDialog = {
  deleteSuccesstext: 'Registration deleted successfully.',
};
export const gridColumnActionConst = {
  editAction: {
    key: 'actionsEdit',
    name: 'Edit Action',
    visibleActions: {
      key: 'Edit registration',
      text: 'Edit registration',
      title: 'Edit Registration',
      iconName: 'EditSolid12',
      iconDisplayName: 'Edit',
    },
  },
  deleteAction: {
    key: 'actionsDelete',
    name: 'Delete Action',
    visibleActions: {
      key: 'Delete',
      text: 'Delete',
      title: 'Delete registration',
      iconName: 'Delete',
      iconDisplayName: 'Delete',
      hover: 'Delete',
    },
  },
};
export const gridColumn: CoherenceDataGridColumn<IUserDetails>[] = [
  {
    key: 'licensePlate',
    name: 'License Plate',
    fieldName: 'vehicleLicense',
    type: 'string',
    isResizable: true,
    minColumnWidth: 85,
    ariaLabel: 'License plate Column. Column is sortable',
  },
  {
    key: 'make/model',
    name: 'Make / Model',
    fieldName: 'makeAndModel',
    type: 'string',
    isResizable: true,
    minColumnWidth: 100,
    ariaLabel: 'Vehicle Make/Model Column. Column is sortable',
  },
  {
    key: 'year',
    name: 'Year',
    fieldName: 'makeYear',
    type: 'string',
    isResizable: true,
    minColumnWidth: 40,
    ariaLabel: 'Vehicle Make year Column. Column is sortable',
  },
  {
    key: 'color',
    name: 'Color',
    fieldName: 'vehicleColor',
    type: 'string',
    isResizable: true,
    minColumnWidth: 45,
    ariaLabel: 'Vehicle color Column. Column is sortable',
  },
  {
    key: 'state',
    name: 'State',
    fieldName: 'vehicleState',
    type: 'string',
    isResizable: true,
    minColumnWidth: 40,
    ariaLabel: 'Vehicle registration state Column. Column is sortable',
  },
  {
    key: 'msPermit',
    name: 'Permit',
    fieldName: 'msPermit',
    type: 'string',
    isResizable: true,
    minColumnWidth: 70,
    ariaLabel: 'Vehicle Permit Number Column. Column is sortable',
  },
  {
    key: 'issuedate',
    name: 'Reg. Date',
    fieldName: 'issuedate',
    type: 'string',
    isResizable: true,
    minColumnWidth: 70,
    ariaLabel: 'Registration date Column. Column is sortable',
  },
  {
    key: 'owner',
    name: 'Owner',
    fieldName: 'owner',
    type: 'string',
    isResizable: true,
    minColumnWidth: 70,
    ariaLabel: 'vehicle owner Column. Column is sortable',
  },
  {
    key: 'regType',
    name: 'Reg.Type',
    fieldName: 'regType',
    type: 'string',
    isResizable: true,
    minColumnWidth: 70,
    ariaLabel: 'Registration Type Column. Column is sortable',
  },
  {
    key: 'carpool',
    name: 'Carpool',
    fieldName: 'carpool',
    type: 'string',
    isResizable: true,
    minColumnWidth: 60,
    ariaLabel: 'Carpool status Column. Column is sortable',
  },
];
export const deleteRegistrationDialogProps: IDialogContentProps = {
  type: DialogType.normal,
  title: 'Delete Registration',
  closeButtonAriaLabel: 'Close',
  subText: `This vehicle and its information will be deleted. Are you sure you want to continue?`,
};
export const gridDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};
export const buttonText = {
  ok: 'Ok',
  cancel: 'Cancel',
};
export const dataGrid = {
  gridDialog,
  gridColumnActionConst,
  gridColumn,
  deleteRegistrationDialogProps,
  gridDragOptions,
  buttonText,
};
export const emptyString = '';
export const EmptySpaceString = ' ';
export const token = 'token';
export const sliceName = {
  userSliceName: 'authUser',
  tokenSliceName: 'authToken',
  tokenSmartParkingSliceName: 'smartParkingToken',
  ownerDetailSliceName: 'ownerDetails',
  vehicleDetailSliceName: 'vehicleDetails',
  registerVehicleSliceName: 'registervehicle',
  registerVehicleErrorSliceName: 'registervehicleError',
  formStateSliceName: 'formState',
  carpoolSliceName: 'Carpool',
  carpoolErrorSliceName: 'CarpoolError',
  carpoolCheckedSliceName: 'CarpoolChecked',
  infoBadgeSliceName: 'infobadge',
  carpoolIsErrorSliceName: 'IsCarpoolError',
  registrationIdSliceName: 'registrationId',
  vehicleRegistrationSliceName: 'vehicleRegistration',
  emailDetailSlice: 'email',
};
export const errorConstants = {
  tokenError: 'Failed to acquire token',
  noAccountDetected: 'No account detected',
};
export enum registrationType {
  SERVICE = 1,
  PERSONAL = 2,
  RENTAL = 3,
  SPONSORED = 4,
  VISITOR = 5,
}
export const errorBadgeMessage = {
  FirstNameErrorMessage: '- First name is blank',
  LastNameErrorMessage: '- Last name is blank',
  VehicleTypeErrorMessage: '- Vehicle type is not selected',
  ParkingLocationErrorMessage: '- Parking location is not selected',
  MakeErrorMessage: '- Vehicle make is blank',
  ModelErrorMessage: '- Vehicle model is blank',
  ColorErrorMessage: '- Color is not selected',
  YearErrorMessage: '- Year is not selected',
  RegistrationTypeErrorMessage: '- Registration type is not selected',
  LicensePlateErrorMessage: '- License plate # is blank',
  StateErrorMessage: '- State is not selected',
  PolicyCheckErrorMessage: '- Please check the box signifying that you understand the Parking Policy',
};
export const carpoolErrorMessage = {
  memberTypeErrorMessage: ' Carpool Group Member Type is not selected',
  aliasErrorMessage: ' Carpool Group Member Alias is blank or invalid',
  originCityErrorMessage: ' Carpool Group Origin City is blank',
  policyCheckErrorMessage: '- Please check the box signifying that you understand the Carpool Policy',
  carpoolMemberCountErrorMessage: '- Please add members in carpool group',
  emailErrorMessage: ' Carpool Group Member Email is blank or invalid',
  aliasConflictErrorMessage: ` Carpool Group Member alias conflicts with another member's email/alias`,
  emailConflictWithAliasErrorMessage: ` Carpool Group Member email conflicts with another member's alias`,
  emailConflictErrorMessage: ` Carpool Group Member email conflicts with another member's email`,
};
export const errorListheading = {
  errorHeadingText: 'Please correct the following problems and resubmit:',
};
export const spinnerMessage = {
  dataGrid: {
    isLoading: 'Loading registered vehicle details',
    isError: 'Error fetching vehicle details.',
    noResultsFound: 'No record found.',
  },
  app: {
    loading: 'Loading...',
    error: 'Error loading application',
  },
};
export const dialogDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};
export const deleteCarpoolDialogProps: IDialogContentProps = {
  type: DialogType.normal,
  title: 'Delete Carpool Member',
  closeButtonAriaLabel: 'Close',
  subText: `This Carpool member and its information will be deleted. Are you sure you want to continue?`,
};
export const dialogtest = {
  onClickOk: 'Ok',
  onClickCancel: 'Cancel',
  deletedtext: 'Carpool member deleted successfully.',
};

export enum MemberType {
  MsEmployee = 'MS Employee',
  NonMsEmployee = 'Non MS Employee',
  ScoopEmployee = 'Scoop Employee',
}
export const infoBadgeDisplayMessage = {
  create: {
    success: 'Vehicle registered successfully.',
    error: 'The vehicle registration could not be completed.',
  },
  update: {
    success: 'Registration has been updated successfully.',
    error: 'The vehicle update could not be completed.',
  },
  delete: {
    success: 'Successfully deleted this vehicle registration.',
    error: 'The vehicle registration could not be deleted.',
  },
  duplicate: {
    apiError: 'Vehicle is already registered.',
    uiError: 'Duplicate license plate. The license plate you specified was already entered for another vehicle.',
  },
};
export const carpoolInfoMessage = {
  create: {
    success: 'Carpool member registered successfully.',
    error: 'The carpool member registration could not be completed.',
  },
  update: {
    success: 'Registration has been updated successfully.',
    error: 'The carpool member update could not be completed.',
  },
  delete: {
    success: 'Carpool member deleted successfully.',
    error: 'The carpool member could not be deleted.',
  },
};

export const Motorcycle = 'Motorcycle';

export const apiTruckType = 'Truck';
export const uiTruckType = 'Truck/Pick-up';

export const initialAdminSearchParamsInputState: IAdminSearchParamInputState = {
  aliasParamInput: '',
  licenseParamInput: '',
  permitParamInput: '',
};
export const AdminScreenInputParamConst = {
  aliasParamName: 'aliasParamInput',
  licenseParamName: 'licenseParamInput',
  permitParamName: 'permitParamInput',
};

export const adminSearchFieldErrorMessages = {
  aliasParamErrorMessage: 'Alias field cannot be empty',
  licenseParamErrorMessage: 'License plate field cannot be empty',
  permitParamErrorMessage: 'Permit field cannot be empty',
};

export enum ConfigKey {
  'CarpoolUrl' = 'ParkingService:Carpool:Url',
  'CreateCarpoolGroupUrl' = 'ParkingService:Carpool:CreateGroupUrl',
  'CreateCarpoolGroupAdminUrl' = 'ParkingService:Carpool:CreateGroupAdminUrl',
  'CarpoolEmailUrl' = 'ParkingService:Carpool:EmailUrl',
  'VehicleRegistrationDomain' = 'ParkingService:Registration:Domain',
  'VehicleRegistrationUrl' = 'ParkingService:Registration:Url',
  'VehicleRegistrationAdminUrl' = 'ParkingService:Registration:AdminUrl',
  'ParkingPolicyUrl' = 'ParkingService:Policy:ParkingPolicyUrl',
  'PrivacyStatementUrl' = 'ParkingService:Policy:PrivacyStatementUrl',
  'PolicyCheckLinkHref' = 'ParkingService:Registration:PolicyCheckLinkHref',
  'FaqResourceHref' = 'ParkingService:Registration:FaqResourceHref',
  'PrivacyStatementHref' = 'ParkingService:Registration:PrivacyStatementHref',
  'CarpoolPolicyLinkHref' = 'ParkingService:Carpool:PolicyLinkHref',
  'ParkingRegistrationResourcesHref' = 'ParkingService:Registration:ResourcesHref',
  'MCommutePugetSoundRegionHref' = 'ParkingService:Policy:McommuteUrl',
  'ColorList' = 'ParkingService:Registration:Color',
  'LicenseMaxLength' = 'ParkingService:Registration:LicenseMaxLength',
  'LocationList' = 'ParkingService:Registration:Location',
  'RegistrationTypeList' = 'ParkingService:Registration:RegistrationType',
  'StateList' = 'ParkingService:Registration:State',
  'VehicleTypeList' = 'ParkingService:Registration:VehicleType',
  'NeedHelpText' = 'ParkingService:Registration:NeedHelpText',
  'MCommutePugetRegionText' = 'ParkingService:Registration:MCommutePugetRegionText',
  'ChaufferSectionFeatureFlag' = '.appconfig.featureflag/chaufferSectionFlag',
}

export const myHubAwarenessSectionText = {
  label:
    'Discover hassle-free parking and easily locate your vehicle with our intuitive MyHub Parking App! Scan the QR code below and experience seamless parking solutions in East Campus (EC) garage.',
};

export const chaufferDetails = {
  chaufferName: {
    name: 'chaufferName',
    placeHolder: 'Chauffer Name',
    label: 'Chauffer Name',
  },
  drivingLicense: {
    name: 'chaufferDrivingLicense',
    placeHolder: 'License Number',
    label: 'Chauffer Driving License',
  },
  contactNumber: {
    name: 'chaufferContactNumber',
    placeHolder: 'Chauffer Contact Number',
    label: 'Chauffer Contact Number',
  },
};

export const countryListToShowChaufferSection = ['IN'];
