export enum requestTriggerType {
  Empty,
  Alias,
  License,
  Permit,
}

export interface IAdminSearchParamInputState {
  aliasParamInput: string;
  licenseParamInput: string;
  permitParamInput: string;
}
